<script>
import { mapState } from "vuex";
import { redirect_prefix } from "@/utils/constant";
export default {
  computed: {
    ...mapState(["curAppData"]),
  },
  created() {
    const { params, query, fullPath } = this.$route;
    if (!fullPath.includes(redirect_prefix)) return;
    const { path } = params;
    if (!!query["p_id"]) {
      this.$router.replace({ path: "/" + path, query });
    } else {
      let loadingInstance = this.$loading();
      let timer = setInterval(() => {
        query["p_id"] = this.curAppData.id;
        if (this.curAppData.id) {
          clearInterval(timer);
          loadingInstance.close();
          this.$router.replace({ path: "/" + path, query });
        }
      }, 100);
    }
  },
  render: function (h) {
    return h(); // avoid warning message
  },
};
</script>
